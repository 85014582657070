export const moviesActionTypes = {
    FETCH_ACTION_MOVIES_REQUEST: 'FETCH_ACTION_MOVIES_REQUEST',
    FETCH_ACTION_MOVIES_SUCCESS: 'FETCH_ACTION_MOVIES_SUCCESS',
    FETCH_ACTION_MOVIES_FAILURE: 'FETCH_ACTION_MOVIES_FAILURE',
    LOAD_MORE_ACTION_MOVIES_SUCCESS: 'LOAD_MORE_ACTION_MOVIES_SUCCESS',

    FETCH_ADVENTURE_MOVIES_REQUEST: 'FETCH_ADVENTURE_MOVIES_REQUEST',
    FETCH_ADVENTURE_MOVIES_SUCCESS: 'FETCH_ADVENTURE_MOVIES_SUCCESS',
    FETCH_ADVENTURE_MOVIES_FAILURE: 'FETCH_ADVENTURE_MOVIES_FAILURE',
    LOAD_MORE_ADVENTURE_MOVIES_SUCCESS: 'LOAD_MORE_ADVENTURE_MOVIES_SUCCESS',

    FETCH_ANIMATION_MOVIES_REQUEST: 'FETCH_ANIMATION_MOVIES_REQUEST',
    FETCH_ANIMATION_MOVIES_SUCCESS: 'FETCH_ANIMATION_MOVIES_SUCCESS',
    FETCH_ANIMATION_MOVIES_FAILURE: 'FETCH_ANIMATION_MOVIES_FAILURE',
    LOAD_MORE_ANIMATION_MOVIES_SUCCESS: 'LOAD_MORE_ANIMATION_MOVIES_SUCCESS',

    FETCH_COMEDY_MOVIES_REQUEST: 'FETCH_COMEDY_MOVIES_REQUEST',
    FETCH_COMEDY_MOVIES_SUCCESS: 'FETCH_COMEDY_MOVIES_SUCCESS',
    FETCH_COMEDY_MOVIES_FAILURE: 'FETCH_COMEDY_MOVIES_FAILURE',
    LOAD_MORE_COMEDY_MOVIES_SUCCESS: 'LOAD_MORE_COMEDY_MOVIES_SUCCESS',

    FETCH_HORROR_MOVIES_REQUEST: 'FETCH_HORROR_MOVIES_REQUEST',
    FETCH_HORROR_MOVIES_SUCCESS: 'FETCH_HORROR_MOVIES_SUCCESS',
    FETCH_HORROR_MOVIES_FAILURE: 'FETCH_HORROR_MOVIES_FAILURE',
    LOAD_MORE_HORROR_MOVIES_SUCCESS: 'LOAD_MORE_HORROR_MOVIES_SUCCESS',

    FETCH_NETFLIX_MOVIES_REQUEST: 'FETCH_NETFLIX_MOVIES_REQUEST',
    FETCH_NETFLIX_MOVIES_SUCCESS: 'FETCH_NETFLIX_MOVIES_SUCCESS',
    FETCH_NETFLIX_MOVIES_FAILURE: 'FETCH_NETFLIX_MOVIES_FAILURE',
    LOAD_MORE_NETFLIX_MOVIES_SUCCESS: 'LOAD_MORE_NETFLIX_MOVIES_SUCCESS',

    FETCH_ROMANCE_MOVIES_REQUEST: 'FETCH_ROMANCE_MOVIES_REQUEST',
    FETCH_ROMANCE_MOVIES_SUCCESS: 'FETCH_ROMANCE_MOVIES_SUCCESS',
    FETCH_ROMANCE_MOVIES_FAILURE: 'FETCH_ROMANCE_MOVIES_FAILURE',
    LOAD_MORE_ROMANCE_MOVIES_SUCCESS: 'LOAD_MORE_ROMANCE_MOVIES_SUCCESS',

    FETCH_TOP_RATED_MOVIES_REQUEST: 'FETCH_TOP_RATED_MOVIES_REQUEST',
    FETCH_TOP_RATED_MOVIES_SUCCESS: 'FETCH_TOP_RATED_MOVIES_SUCCESS',
    FETCH_TOP_RATED_MOVIES_FAILURE: 'FETCH_TOP_RATED_MOVIES_FAILURE',
    LOAD_MORE_TOP_RATED_MOVIES_SUCCESS: 'LOAD_MORE_TOP_RATED_MOVIES_SUCCESS',
    
    FETCH_TRENDING_MOVIES_REQUEST: 'FETCH_TRENDING_MOVIES_REQUEST',
    FETCH_TRENDING_MOVIES_SUCCESS: 'FETCH_TRENDING_MOVIES_SUCCESS',
    FETCH_TRENDING_MOVIES_FAILURE: 'FETCH_TRENDING_MOVIES_FAILURE',
    LOAD_MORE_TRENDING_MOVIES_SUCCESS: 'LOAD_MORE_TRENDING_MOVIES_SUCCESS',

    FETCH_UPCOMING_MOVIES_REQUEST: 'FETCH_UPCOMING_MOVIES_REQUEST',
    FETCH_UPCOMING_MOVIES_SUCCESS: 'FETCH_UPCOMING_MOVIES_SUCCESS',
    FETCH_UPCOMING_MOVIES_FAILURE: 'FETCH_UPCOMING_MOVIES_FAILURE',
    LOAD_MORE_UPCOMING_MOVIES_SUCCESS: 'LOAD_MORE_UPCOMING_MOVIES_SUCCESS',

    FETCH_LATEST_MOVIES_REQUEST: 'FETCH_LATEST_MOVIES_REQUEST',
    FETCH_LATEST_MOVIES_SUCCESS: 'FETCH_LATEST_MOVIES_SUCCESS',
    FETCH_LATEST_MOVIES_FAILURE: 'FETCH_LATEST_MOVIES_FAILURE',
    LOAD_MORE_LATEST_MOVIES_SUCCESS: 'LOAD_MORE_LATEST_MOVIES_SUCCESS'
}