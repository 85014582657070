import "./playAnimation.scss";
import { useEffect, useReducer, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { TADUM_SOUND_URL } from "../../requests";
import ReactPlayer from "react-player";
import Player from "@vimeo/player";
import { useParams } from "react-router-dom";
const Button = ({id}) => {
let history = useHistory();

  return (
    <button
      onClick={() => {
        history.push("/browse");
        // history.push("/browse?returningFrom="+id)
      }}
      className={`PlayAnimation__button`}
    >
      Go back
    </button>
  );
};

const PlayAnimation = () => {
  let history = useHistory();
  const params = useParams();
  // const soundRef = useRef(null);
  // const handleTadum = () => {
  // 	soundRef.current.currentTime = 0;
  // 	soundRef.current.play();
  // }

  // useEffect(() => {
  // 	handleTadum();
  // 	setTimeout(() => {
  // 		history.push('/browse')
  // 	}, 4200)
  // }, [history])
console.log({params});

  useEffect(() => {
	    const iframe = document.querySelector("iframe");
      	const player = new Player(iframe);
		// player.play();
		player.on("ended", function () {
		history.push("/browse?returningFrom="+params.id);
		// history.push("/browse");
		});
    // player.getVideoTitle().then(function (title) {
    //   console.log("title:", title);
    // });
    // var onPlay = function (data) {
    //   // `data` is an object containing properties specific to that event
    // };

    // player.on("play", onPlay);
    // document.onmousemove =
    //   function (evt) {
    // 	  console.log(evt);
    //     setLastMoveTime(new Date().getTime());
    //   };

    // document.onkeyup =
    //   function (evt) {
    //     console.log("x");
    //     evt = evt || window.event;
    //     if (evt.keyCode == 27) {
    //       history.push("/browse");
    //     }
    //   };
    // return () => {
    // 	document.onkeydown = null;
    // 	document.onmousemove = null;
    // }
  }, []);
  return (
    <div className="PlayAnimation__wrp">
      <Button id={params.id} />

      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        <iframe
          id="iframe"
          src={`https://player.vimeo.com/video/${params["id"]}?h=a44eba06dc&amp;badge=0&amp;autopause=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479`}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          title="Makłowicz w SWM"
        ></iframe>
      </div>
    </div>
  );

  /* <ReactPlayer
        controls={true}
        width="100%"
        config={{
          vimeo: {
            playerOptions: {
              autoplay: true,
              transparent: true,
              byline: false,

            },
          },
        }}
        onEnded={() => history.push("/browse")}
        height="100%"
        url={
          "https://player.vimeo.com/video/707084804?h=c5514b7eb9&autoplay=1&title=0&byline=0&portrait=0"
        }
      ></ReactPlayer> */
  // return (

  // 		<audio ref={soundRef} src={TADUM_SOUND_URL} />
  // 		<span className="PlayAnimation__text">
  // 			FAKEFLIX
  // 		</span>
  // 	</div>
  // )
};

export default PlayAnimation;
