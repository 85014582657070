import requests from "./requests";
import * as movieSelectors from "./redux/movies/movies.selectors";
import * as seriesSelectors from "./redux/series/series.selectors";
import {
	fetchActionMoviesAsync,
	fetchAdventureMoviesAsync,
	fetchAnimationMoviesAsync,
	fetchComedyMoviesAsync,
	fetchHorrorMoviesAsync,
	fetchLatestMoviesAsync,
	fetchNetflixMoviesAsync,
	fetchRomanceMoviesAsync,
	fetchTopRatedMoviesAsync,
	fetchTrendingMoviesAsync,
	fetchUpcomingMoviesAsync,
} from "./redux/movies/movies.actions";
import {
	fetchActionAdventureSeriesAsync,
	fetchAnimationSeriesAsync,
	fetchComedySeriesAsync,
	fetchCrimeSeriesAsync,
	fetchDocumentarySeriesAsync,
	fetchFamilySeriesAsync,
	fetchKidsSeriesAsync,
	fetchNetflixSeriesAsync,
	fetchSciFiFantasySeriesAsync,
	fetchTrendingSeriesAsync,
} from "./redux/series/series.actions";
// const {
// 	fetchReleasedMoviesByOneMonth,
// 	fetchTrendingMovies,
// 	fetchNetflixOriginals,
// 	fetchTopRated,
// 	fetchActionMovies,
// 	fetchAdventureMovies,
// 	fetchComedyMovies,
// 	fetchHorrorMovies,
// 	fetchRomanceMovies,
// 	fetchAnimationMovies,
// 	fetchUpcomingMovies,
// 	fetchActionAdventureSeries,
// 	fetchAnimationSeries,
// 	fetchComedySeries,
// 	fetchCrimeSeries,
// 	fetchDocumentarySeries,
// 	fetchFamilySeries,
// 	fetchKidsSeries,
// 	fetchSciFiFantasySeries,
// 	fetchTrendingSeries,
// } = requests;

export const fetchMovieDataConfig = [
  //   {
  //     id: 0,
  //     thunk: fetchTrendingMoviesAsync,
  //     url: "",
  //     title: "Trending Now",
  //     genre: "trending",
  //     selector: movieSelectors.selectTrendingMovies,
  //   },
  {
    id: 1,
    thunk: fetchNetflixMoviesAsync,
    url: "",
    title: "Więcej Makłowicza",
    genre: "trending",
    selector: movieSelectors.selectNetflixMovies,
  },
];

export const fetchSeriesDataConfig = [];

export const fetchPopularDataConfig = [];

export const genresList = [];
